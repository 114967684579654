import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RiskStatus } from '../../../common/RiskStatus';
import { RiskColumnDefinitionData } from './common';
import { SegmentedControlProps } from '@amzn/awsui-components-react';
import {
  ResourceRevisionList,
  ResourceRevisionStructure
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import i18n from '../../../i18n';

export const ALL_VALUE = 'All';

export const allSelectOption: OptionDefinition = { label: ALL_VALUE, value: ALL_VALUE };

export const ackStatusOption: OptionDefinition = { label: 'Acknowledged', value: RiskStatus.ACKNOWLEDGED };

export const unackStatusOption: OptionDefinition = { label: 'Unacknowledged', value: RiskStatus.UNACKNOWLEDGED };

export const statusSelectOptions: OptionDefinition[] = [allSelectOption, ackStatusOption, unackStatusOption];

export const riskDefaultDisplayOption: SegmentedControlProps.Option = { text: i18n.t('Default'), id: 'default' };

export const riskByRuleDisplayOption: SegmentedControlProps.Option = {
  text: i18n.t('Group by Rule'),
  id: 'groupByRule'
};

export const riskDisplayOptions: SegmentedControlProps.Option[] = [riskDefaultDisplayOption, riskByRuleDisplayOption];

const allFiltersSetToAll = (filters: (OptionDefinition | undefined)[]) => {
  for (const filter of filters) {
    if (filter?.value !== allSelectOption.value) {
      return false;
    }
  }
  return true;
};

const matchingStatus = (statusFilter: OptionDefinition, item: RiskColumnDefinitionData) => {
  return statusFilter.value === allSelectOption.value || statusFilter.value === item.status;
};

const matchingRule = (item: RiskColumnDefinitionData, ruleFilter?: OptionDefinition) => {
  return !ruleFilter || ruleFilter.value === allSelectOption.value || ruleFilter.value === item.ruleId;
};

const matchingResourceType = (resourceTypeFilter?: OptionDefinition, resourceRevision?: ResourceRevisionStructure) => {
  return (
    !resourceTypeFilter ||
    resourceTypeFilter.value === allSelectOption.value ||
    resourceTypeFilter.value === resourceRevision?.resourceType
  );
};

const matchingItem = (
  statusFilter: OptionDefinition,
  item: RiskColumnDefinitionData,
  ruleFilter?: OptionDefinition,
  resourceTypeFilter?: OptionDefinition,
  resourceRevision?: ResourceRevisionStructure
) => {
  return (
    matchingStatus(statusFilter, item) &&
    matchingRule(item, ruleFilter) &&
    matchingResourceType(resourceTypeFilter, resourceRevision)
  );
};

const extractRulesFromRiskItems = (items: RiskColumnDefinitionData[]): OptionDefinition[] => {
  const ruleMap = items.reduce((rules, item) => {
    return rules.set(item.ruleId, { label: item.ruleName, value: item.ruleId });
  }, new Map<string, OptionDefinition>());
  return [...ruleMap.values()];
};

const extractTypesFromResourceRevisionItems = (items: ResourceRevisionList): OptionDefinition[] => {
  const typeMap = items.reduce((types, item) => {
    return types.set(item.resourceType, { label: item.resourceType, value: item.resourceType });
  }, new Map<string, OptionDefinition>());
  return [...typeMap.values()];
};

const findFilterFromParam = (filterOptions: OptionDefinition[], param: string | null) => {
  return filterOptions.find((option) => {
    return option.value?.toLowerCase() === param?.toLowerCase();
  });
};

const findSegmentFromParam = (segmentOptions: SegmentedControlProps.Option[], param: string | null) => {
  return segmentOptions.find((option) => {
    return option.id.toLowerCase() === param?.toLowerCase();
  });
};

enum SearchParams {
  RULE = 'rule',
  RISK_STATUS = 'riskStatus',
  RESOURCE_TYPE = 'resourceType',
  RISK_DISPLAY = 'riskDisplay'
}

export {
  SearchParams,
  allFiltersSetToAll,
  matchingStatus,
  matchingRule,
  matchingItem,
  extractRulesFromRiskItems,
  extractTypesFromResourceRevisionItems,
  findFilterFromParam,
  findSegmentFromParam
};
