import { SpaceBetween } from '@amzn/awsui-components-react';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import React from 'react';
import ResourceRevisionTable from './ResourceRevisionTable';

interface ResourceRevisionsTabProps {
  resourceRevisionItems: ResourceRevisionList;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean | undefined;
}

const ResourceRevisionsTab = ({
  resourceRevisionItems,
  isLoadingResourceRevisions,
  hasMoreResourceRevisions
}: ResourceRevisionsTabProps) => {
  return (
    <SpaceBetween size='s'>
      <ResourceRevisionTable
        tableItems={resourceRevisionItems}
        isLoadingResourceRevisions={isLoadingResourceRevisions}
        hasMoreResourceRevisions={hasMoreResourceRevisions || false}
        isFiltering={false}
      />
    </SpaceBetween>
  );
};

export default ResourceRevisionsTab;
