import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LandingPage from './components/landing-page';
import LayoutHousing from './components/layout-housing';
import PageNotFound from './components/PageNotFound';
import Review from './components/reviews/Review';
import RuleDetails from './components/rules/RuleDetails';

export const ROUTES = createRoutesFromElements(
  <Route path='/' element={<LayoutHousing />}>
    <Route index element={<LandingPage />} />
    <Route path='/reviews/:reviewId' element={<Review />} />
    <Route path='/rules/:ruleId' element={<RuleDetails />} />
    <Route path='/*' element={<PageNotFound />} />
  </Route>
);

export default createBrowserRouter(ROUTES);
