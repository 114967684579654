import React, { useState } from 'react';
import { Tabs, TabsProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import RisksTab from './risks/RiskTab';
import ResourceRevisionsTab from './resource-revisions/ResourceRevisionTab';
import { RiskColumnDefinitionData } from './table-definitions/common';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import {
  ALL_CHANGES_TAB_ID,
  ALL_CHANGES_TAB_LABEL,
  RISK_TAB_ID,
  RISK_TAB_LABEL
} from './table-definitions/review-tabs';

interface ReviewTabsProps {
  riskItems: RiskColumnDefinitionData[];
  riskTableItems: RiskColumnDefinitionData[];
  riskDisplayOption: string;
  resourceRevisionItems: ResourceRevisionList;
  isLoadingRisks: boolean;
  hasMoreRisks: boolean;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean;
  isMutating: boolean;
  isFiltering: boolean;
}

const ReviewTabs = ({
  riskItems,
  riskTableItems,
  riskDisplayOption,
  resourceRevisionItems,
  isLoadingRisks,
  hasMoreRisks,
  isLoadingResourceRevisions,
  hasMoreResourceRevisions,
  isMutating,
  isFiltering
}: ReviewTabsProps) => {
  const [activeTabId, setActiveTabId] = useState(RISK_TAB_ID);

  return (
    <Tabs
      onChange={({ detail }: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => setActiveTabId(detail.activeTabId)}
      activeTabId={activeTabId}
      tabs={[
        {
          label: RISK_TAB_LABEL,
          id: RISK_TAB_ID,
          content: (
            <RisksTab
              riskItems={riskItems}
              riskTableItems={riskTableItems}
              riskDisplayOption={riskDisplayOption}
              resourceRevisionItems={resourceRevisionItems}
              isLoadingRisks={isLoadingRisks}
              hasMoreRisks={hasMoreRisks}
              isLoadingResourceRevisions={isLoadingResourceRevisions}
              hasMoreResourceRevisions={hasMoreResourceRevisions}
              isMutating={isMutating}
              isFiltering={isFiltering}
            />
          )
        },
        {
          label: ALL_CHANGES_TAB_LABEL,
          id: ALL_CHANGES_TAB_ID,
          content: (
            <>
              <ResourceRevisionsTab
                resourceRevisionItems={resourceRevisionItems}
                isLoadingResourceRevisions={isLoadingResourceRevisions}
                hasMoreResourceRevisions={hasMoreResourceRevisions}
              />
            </>
          )
        }
      ]}
    />
  );
};

export default ReviewTabs;
