import React, { useContext, useEffect } from 'react';
import {
  ColumnLayout,
  Container,
  FormField,
  SegmentedControl,
  SpaceBetween,
  SegmentedControlProps
} from '@amzn/awsui-components-react';
import {
  allSelectOption,
  statusSelectOptions,
  extractRulesFromRiskItems,
  unackStatusOption,
  findFilterFromParam,
  SearchParams,
  extractTypesFromResourceRevisionItems,
  riskDefaultDisplayOption,
  riskByRuleDisplayOption,
  riskDisplayOptions,
  findSegmentFromParam
} from './table-definitions/filter-menu';
import { someRiskStatus } from './table-definitions/risk-table';
import { RiskColumnDefinitionData } from './table-definitions/common';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RisksContext } from './context/RisksContext';
import DynamicSelect from './DynamicSelect';
import { RiskStatus } from '../../common/RiskStatus';
import { useSearchParams } from 'react-router-dom';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

interface FilterMenuProps {
  readonly risksItems: RiskColumnDefinitionData[];
  readonly resourceRevisionsItems: ResourceRevisionList;
  isLoadingRisks: boolean;
  isLoadingResourceRevisions: boolean;
}

const FilterMenu = ({
  risksItems,
  resourceRevisionsItems,
  isLoadingRisks,
  isLoadingResourceRevisions
}: FilterMenuProps) => {
  const [searchParams] = useSearchParams();
  const risksControls = useContext(RisksContext);

  const ruleOptions = [allSelectOption].concat(extractRulesFromRiskItems(risksItems));
  const resourceTypeOptions = [allSelectOption].concat(extractTypesFromResourceRevisionItems(resourceRevisionsItems));

  useEffect(() => {
    if (isLoadingRisks || isLoadingResourceRevisions) return;

    const riskStatusParam = searchParams.get(SearchParams.RISK_STATUS);
    const ruleParam = searchParams.get(SearchParams.RULE);
    const resourceTypeParam = searchParams.get(SearchParams.RESOURCE_TYPE);
    const riskDisplayParam = searchParams.get(SearchParams.RISK_DISPLAY);

    const riskStatusValue = getDefaultRiskStatus(
      findFilterFromParam(statusSelectOptions, riskStatusParam),
      someRiskStatus(risksItems, RiskStatus.UNACKNOWLEDGED)
    );
    const riskDisplayValue = getDefaultRiskDisplay(findSegmentFromParam(riskDisplayOptions, riskDisplayParam));

    const ruleValue = findFilterFromParam(ruleOptions, ruleParam) || allSelectOption;
    const resourceTypeValue = findFilterFromParam(resourceTypeOptions, resourceTypeParam) || allSelectOption;

    risksControls.updateRiskDisplay(riskDisplayValue);
    risksControls.filterRisks(riskStatusValue, ruleValue, resourceTypeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRisks, isLoadingResourceRevisions, risksItems, resourceRevisionsItems]);

  return (
    <Container>
      <SpaceBetween size='s'>
        <ColumnLayout columns={4}>
          <FormField label='Risk status' stretch={true}>
            <DynamicSelect
              isLoading={isLoadingRisks}
              setFilter={risksControls.filterRiskStatus}
              testId='risk-status-select-filter'
              options={statusSelectOptions}
              value={risksControls.riskStatusFilterValue}
              ariaLabel='Selected Risk Status'
            />
          </FormField>
          <FormField label='Rule' stretch={true}>
            <DynamicSelect
              isLoading={isLoadingRisks}
              setFilter={risksControls.filterRule}
              testId='risk-rule-select-filter'
              options={ruleOptions}
              value={risksControls.ruleFilterValue}
              ariaLabel='Selected Rule'
            />
          </FormField>
          <FormField label='Resource type' stretch={true}>
            <DynamicSelect
              isLoading={isLoadingRisks || isLoadingResourceRevisions}
              setFilter={risksControls.filterResourceType}
              testId='risk-resource-type-select-filter'
              options={resourceTypeOptions}
              value={risksControls.resourceTypeFilterValue}
              ariaLabel='Selected Resource Type'
            />
          </FormField>
          <FormField label='Risk display' stretch={true}>
            <SegmentedControl
              selectedId={risksControls.riskDisplayControlValue}
              onChange={({ detail }) => risksControls.updateRiskDisplay(detail.selectedId)}
              options={[riskDefaultDisplayOption, riskByRuleDisplayOption]}
              data-testid='risk-display-segmented-control'
            />
          </FormField>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
};

/**
 * The risk status search param overwrites the default filter if exists.
 * If the search param does not exist the default filter is unacknowledged if some risks are unacknowledged, all otherwise.
 *
 * @param riskStatusOption
 * @param someRisksUnack
 * @returns The default value for the risk status filter
 */
export function getDefaultRiskStatus(riskStatusOption: OptionDefinition | undefined, someRisksUnack: boolean) {
  if (riskStatusOption !== undefined) return riskStatusOption;

  if (someRisksUnack) return unackStatusOption;

  return allSelectOption;
}

/**
 * The risk display search param overwrites the default filter if exists.
 * If the search param does not exist we return the default display option.
 *
 * @param riskDisplayOption
 * @returns The default value for the risk display control
 */
function getDefaultRiskDisplay(riskDisplayOption: SegmentedControlProps.Option | undefined) {
  return riskDisplayOption?.id ?? riskDefaultDisplayOption.id;
}

export default FilterMenu;
