import {
  ResourceRevisionList,
  ResourceRevisionStructure
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import { getResourceOperationDisplay } from '../resource-revisions/ResourceRevisionOperation';
import { resourceIdCell } from './common';
import i18n from '../../../i18n';

const PAGE_SIZE = 20;

const TRACK_BY = 'resourceId';

interface ResourceRevisionTableProps {
  readonly tableItems: ResourceRevisionList;
  isLoadingResourceRevisions: boolean;
  hasMoreResourceRevisions: boolean;
  isFiltering: boolean;
}

const columnDefinitions = (reviewId: string, splitPanelContext: SplitPanelContextProps) => [
  {
    id: 'resource',
    header: i18n.t('Resource'),
    cell: (obj: ResourceRevisionStructure) => resourceIdCell(obj, String(reviewId), splitPanelContext),
    sortingField: 'resourceId'
  },
  {
    id: 'resource-type',
    header: i18n.t('Resource Type'),
    cell: (obj: ResourceRevisionStructure) => obj.resourceType,
    sortingField: 'resourceType'
  },
  {
    id: 'resource-operation',
    header: i18n.t('Operation'),
    cell: (obj: ResourceRevisionStructure) => getResourceOperationDisplay(obj),
    sortingField: 'operation'
  },
  {
    id: 'container',
    header: i18n.t('Container'),
    cell: (obj: ResourceRevisionStructure) => obj.resourceContainerId,
    sortingField: 'resourceContainerId'
  }
];

export { columnDefinitions, type ResourceRevisionTableProps, PAGE_SIZE, TRACK_BY };
