import { useCollection } from '@amzn/awsui-collection-hooks';
import { Header, Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SplitPanelContext } from '../../layout-housing/SplitPanel';
import {
  columnDefinitions,
  ResourceRevisionTableProps,
  PAGE_SIZE,
  TRACK_BY
} from '../table-definitions/resource-revision-table';
import {
  TableEmptyState,
  TableNoMatchState,
  getFilterCounterText,
  onClearTableTextFilterClick
} from '../../../common/common-components';
import { CollectionActions } from '@awsui/collection-hooks/dist/mjs/interfaces';
import { ResourceRevisionStructure } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { useTranslation } from 'react-i18next';

const ResourceRevisionTable = ({
  tableItems,
  hasMoreResourceRevisions,
  isLoadingResourceRevisions
}: ResourceRevisionTableProps) => {
  const { reviewId } = useParams();
  const { t } = useTranslation();
  const splitPanelControls = useContext(SplitPanelContext);
  const [filterActions, setFilterActions] = useState<CollectionActions<ResourceRevisionStructure>>();

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, filterProps } = useCollection(
    tableItems,
    {
      pagination: { pageSize: PAGE_SIZE },
      selection: { keepSelection: true, trackBy: TRACK_BY },
      sorting: {},
      filtering: {
        empty: <TableEmptyState resourceName='Resource revisions' />,
        noMatch: <TableNoMatchState onClearFilterClick={onClearTableTextFilterClick(filterActions)} />
      }
    }
  );

  useEffect(() => {
    setFilterActions(actions);
    // equivalent of willUnmount; reset split panel
    return () => {
      splitPanelControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]);

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions(String(reviewId), splitPanelControls)}
      items={items}
      loading={isLoadingResourceRevisions || hasMoreResourceRevisions}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder={t('Search')}
          countText={getFilterCounterText(filteredItemsCount || 0)}
        />
      }
      header={<Header counter={'(' + tableItems.length.toString() + ')'}>{t('Resource Revisions')}</Header>}
      pagination={<Pagination {...paginationProps} openEnd={hasMoreResourceRevisions} />}
    />
  );
};

export default ResourceRevisionTable;
